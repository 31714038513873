import React from "react";
import { chatIcon, verifiedCheck } from "../../assets";

const ChatCard = ({ profile }) => {
  return (
    <div className="flex items-center p-4 bg-white shadow-lg rounded-3xl w-full max-w-md">
      {/* Left Section: SEBI and Profile Image */}
      <div className="flex-shrink-0 flex flex-col items-center">
        {/* SEBI Text */}
        <div className="flex items-center justify-center text-xs font-semibold mb-2 px-2 py-1 rounded-md">
          <img className="w-4 h-4" src={verifiedCheck} alt="" />
          SEBI
        </div>
        {/* Profile Image */}
        <img
          src={profile.image}
          alt={`${profile.name}`}
          className="w-16 h-16 rounded-full"
        />
      </div>

      {/* Right Section: Content */}
      <div className="ml-4 flex-grow">
        {/* Brand Name and Contact */}
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-semibold">{profile.brand}</h3>
          <span className="text-sm text-gray-500">{profile.contact}</span>
        </div>

        {/* Name, Categories, and Chat Button */}
        <div className="flex items-center justify-between mt-2">
          <div>
            <p className="text-sm text-gray-500 mt-1">{profile.name}</p>
            <p className="text-sm text-gray-500">
              {profile.categories.join(", ")}
            </p>
          </div>
          <button
            className="flex items-center justify-center space-x-1 border-2 border-[#00ACFF33] text-blue-500 bg-blue-100 px-3 py-3 text-sm rounded-2xl"
            aria-label={`Chat now with ${profile.name}`}
          >
            <img className="w-4 h-4" src={chatIcon} alt="" />
            <span>Free Chat</span>
          </button>
        </div>

        {/* Price Details */}
        <div className="flex items-center justify-between mt-2">
          <div>
            <span className="text-sm line-through text-gray-400">
              ₹{profile.originalPrice}
            </span>
            <span className="text-sm text-green-600 font-bold ml-2">
              ₹{profile.discountedPrice}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatCard;
