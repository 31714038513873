import React, { useState } from "react";
import { motion } from "framer-motion";
import styles from "../../style";
import ChatCard from "./ChatCard";

const Chats = () => {
  const [profiles, setProfiles] = useState([]);

  //     const profiles = [
  //     {
  //       brand: "Option Empire",
  //       name: "Subham Gedam",
  //       contact: "-98765432106",
  //       categories: ["Commodity", "Equity", "Options"],
  //       image: "https://via.placeholder.com/64", // Replace with actual image URL
  //       originalPrice: 99,
  //       discountedPrice: "Free",
  //       actionText: "Free Chat",
  //     },
  //     {
  //       brand: "Stock Guru",
  //       name: "Amit Verma",
  //       contact: "-98765432123",
  //       categories: ["Stocks", "Mutual Funds"],
  //       image: "https://via.placeholder.com/64", // Replace with actual image URL
  //       originalPrice: 199,
  //       discountedPrice: "Free",
  //       actionText: "Chat Now",
  //     },
  //     {
  //       brand: "Finance Wizard",
  //       name: "Riya Sharma",
  //       contact: "-98765432145",
  //       categories: ["Equity", "Options"],
  //       image: "https://via.placeholder.com/64", // Replace with actual image URL
  //       originalPrice: 150,
  //       discountedPrice: "Free",
  //       actionText: "Connect",
  //     },
  //   ];
  return (
    <div className="expertise-Bgn">
      <motion.section
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        id="subscription"
        className={`flex md:flex-row flex-col sm:pt-32 pt-24`}
      >
        <div
          className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6 z-10 md:bottom-[10rem] text-center`}
        >
          <div className="text-left w-full">
            <span className="font-inter font-[700] md:text-[62px] text-[36px] text-[#24243F] md:leading-[74px] leading-[48px]">
              Chats
            </span>
          </div>
        </div>
      </motion.section>
      <div className="flex flex-col items-center gap-4 p-6 min-h-screen">
        {profiles.map((profile, index) => (
          <ChatCard key={index} profile={profile} />
        ))}
      </div>
    </div>
  );
};

export default Chats;
